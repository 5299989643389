import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LoginService {
    
    login_emitter: EventEmitter<boolean> = new EventEmitter();

    login() {
        this.login_emitter.emit(true);
    }

    logout() {
        localStorage.removeItem('jwt');
        this.login_emitter.emit(false);
    }
}