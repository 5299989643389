import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';

import { LoginService } from './services/login.service';

import { AppHttpInterceptor } from './http.interceptor';

import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { ObjectListComponent } from './object-list/object-list.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { UploadModalComponent } from './upload-modal/upload-modal.component';
import { NewFolderModalComponent } from './new-folder-modal/new-folder-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@NgModule({
  imports: [
    FormsModule,
    ToastrModule.forRoot(),
    BrowserModule,
    FileUploadModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '**', component: ObjectListComponent },
    ]),
  ],
  declarations: [
    AppComponent,
    TopBarComponent,
    ObjectListComponent,
    LoginModalComponent,
    UploadModalComponent,
    NewFolderModalComponent,
    ConfirmationModalComponent
  ],
  bootstrap: [ AppComponent ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true, deps: [LoginService, ToastrService] },
    LoginService
  ]
})
export class AppModule { }

