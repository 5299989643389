import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from '../services/login.service';
import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";
import { ObjectListComponent } from "../object-list/object-list.component";
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-folder-modal',
  templateUrl: './new-folder-modal.component.html',
  styleUrls: ['./new-folder-modal.component.css']
})
export class NewFolderModalComponent {

  @Input() object_list: ObjectListComponent;

  private jwt = localStorage.getItem('jwt');
  private hidden: boolean = true;

  constructor(private login_service: LoginService, private http: HttpClient, private toastr: ToastrService) {
    this.login_service.login_emitter.subscribe(evt => this.set_logged_in(evt));
  }

  set_logged_in(logged_in) {
    if (logged_in) {
      this.jwt = localStorage.getItem('jwt');
    } else {
      this.jwt = null;
    }
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  new_folder(folder){
    if (folder.length > 0) {
      if (folder[folder.length-1] !== '/') {
        folder += '/';
      }
      this.http.get<any>(
        `${environment.backend}/put/content${this.object_list.get_prefix()}${folder}`
      ).subscribe(result => {
        let data = new FormData();
        for (const [key, value] of Object.entries(result.message.fields)) {
          if (typeof value === 'string') {
            data.append(key, value);
          }
        };
        data.append('file', null);
        const req = new HttpRequest('POST', result.message.url, data, { reportProgress: true });
        this.http.request(req).subscribe(event => {
          if (event.type === HttpEventType.Response) {
            this.hide();
            this.object_list.update_list(this.object_list.get_prefix());
          }
        });
      });
    } else {
      this.toastr.warning('Folder name cannot be empty', 'Folder Name Required');
    }
  }

}
