import { Component, Input, EventEmitter } from '@angular/core';
import { LoginModalComponent } from "../login-modal/login-modal.component";
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})

export class TopBarComponent {

  private jwt = localStorage.getItem('jwt');

  @Input() login_modal: LoginModalComponent;
  
  constructor(private login_service: LoginService) {
    this.login_service.login_emitter.subscribe(evt => this.set_logged_in(evt));
  }

  set_logged_in(logged_in) {
    if (logged_in) {
      this.jwt = localStorage.getItem('jwt');
    } else {
      this.jwt = null;
    }
  }

  login() {
    this.login_modal.show();
  }

  logout() {
    this.login_service.logout();
  }

}

