import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent {

  private hidden: boolean = true;
  private callback = null;
  private callback_param = null;
  private action = "";

  constructor() { }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  confirm(action, callback, callback_param) {
    this.action = action;
    this.callback = callback;
    this.callback_param = callback_param;
    this.show();
  }

  respond(value){
    this.callback(value, this.callback_param);
    this.hide();
  }

}
