import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable, Output, EventEmitter } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { LoginService } from './services/login.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(private login_service: LoginService, private toastr: ToastrService) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let jwt = localStorage.getItem('jwt');
        if (req.url.indexOf(`${environment.backend}`) === 0) {
            if (jwt !== null) {
                req = req.clone({
                    setHeaders: {
                        'authorization': jwt
                    }
                });
            }
        } else {
            req = req.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': req.url,
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type'
                }
            });
        }
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if(evt.body && evt.body.jwt) {
                        localStorage.setItem('jwt', evt.body.jwt);
                    }
                }
            }, (err: any) => {
                if(err instanceof HttpErrorResponse) {
                    if (err.status === 403) {
                        this.toastr.warning(err.error.message, 'Forbidden');
                        if (jwt !== null) {
                            this.login_service.logout();
                            this.toastr.warning('You have been logged out.', 'Logout');
                        }
                    }
                }
            })
        );
    
    }
      
}