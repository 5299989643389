import { Component, Input, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoginModalComponent } from "../login-modal/login-modal.component";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.css'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})

export class ObjectListComponent {
  
  @Input() login_modal: LoginModalComponent;
  @Input() confirmation_modal: ConfirmationModalComponent;
  
  private full_object_list: string[];
  private object_list: string[];
  private prefix_list: string[];
  private prefix: string;
  private loading: boolean = true;
  private filter_regex = new RegExp('.+', 'i');
  private logged_in: boolean = localStorage.getItem('jwt') !== null;

  constructor(private http: HttpClient, private location: Location, private login_service: LoginService, private router: Router, private route: ActivatedRoute) {

    let sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route.queryParams.subscribe(params => {
          if (params.path) {
            this.update_list(params.path, '/public/');
          } else if (params.get) {
            this.prefix = '';
            this.get_object(params.get);
          } else {
            this.update_list('/', '/public/');
          }
        });
        sub.unsubscribe();
      }
    });
    this.login_service.login_emitter.subscribe(evt => this.set_logged_in(evt));
  }

  set_logged_in(logged_in) {
    this.logged_in = logged_in;
    if (logged_in) {
      this.update_list(this.prefix);
    } else {
      this.update_list(this.prefix, '/public/');
    }
  }

  filter_list() {
    this.object_list = this.full_object_list.map(object => object.substring(7 + this.prefix.length)).reduce((result, object) => {
      if ((object.indexOf('/') === -1 || object.indexOf('/') === object.length - 1) && object.match(this.filter_regex)) {
        result.push(object);
      }
      return result;
    }, this.prefix.length > 1 ? ['..'] : []);
  }

  update_list(new_prefix, fallback=null) {
    this.loading = true;
    this.http.get<any>(
      `${environment.backend}/list/content${new_prefix}`
    ).subscribe(result => {
      this.full_object_list = result.message;
      this.prefix = new_prefix;
      this.prefix_list = this.prefix.split('/').filter(obj => obj.length);
      this.prefix_list.unshift('');
      this.filter_list();
      this.router.navigate([], {
        queryParams: {
          path: this.prefix
        },
        relativeTo: this.route
      });
      this.loading = false;
    }, err => {
      if (fallback) {
        this.update_list(fallback);
      } else {
        this.loading = false;
      }
    });
  }

  get_prefix() {
    return this.prefix;
  }

  get_path(object) {
    let path_prefix = 'path';
    if (object[object.length-1] !== '/') {
      path_prefix = 'get';
    }
    return `/?${path_prefix}=${this.prefix}${object}`;
  }

  breadcrumbs(index) {
    this.update_list(this.prefix_list.slice(0, index + 1).join('/') + '/');
  }

  filter(event) {
    if (event.target.value) {
      this.filter_regex = new RegExp(event.target.value, 'i');
    } else {
      this.filter_regex = new RegExp('.+', 'i');
    }
    this.filter_list();
  }

  get_object(object) {
    this.http.get<any>(
      `${environment.backend}/get/content${this.prefix}${object}`
    ).subscribe(result => {
      this.loading = false;
      window.location.replace(result.message);
    });
  }

  delete(object) {
    this.confirmation_modal.confirm("Delete " + object, this.do_delete, {'object': `${this.prefix}${object}`, 'self': this});
  }

  set_loading(loading) {
    this.loading = loading;
  }

  do_delete(response, object) {
    if (response) {
      object.self.do_actual_delete(object.object);
    }
  }

  do_actual_delete(object) {
    this.set_loading(true);
    this.http.get<any>(
    `${environment.backend}/delete/content${object}`
    ).subscribe(result => {
      this.update_list(this.get_prefix());
    });
  }
}
