import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../services/login.service';
import { FileUploader } from "ng2-file-upload";
import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ObjectListComponent } from "../object-list/object-list.component";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.css']
})
export class UploadModalComponent{

  @Input() object_list: ObjectListComponent;
  @ViewChild('file_input') file_input: ElementRef<HTMLElement>;

  private jwt = localStorage.getItem('jwt');
  private hidden: boolean = true;
  private upload_form: FormGroup;
  private uploader: FileUploader = new FileUploader({
    isHTML5: true
  });

  constructor(private login_service: LoginService, private form_builder: FormBuilder, private http: HttpClient) {
    this.login_service.login_emitter.subscribe(evt => this.set_logged_in(evt));
    this.upload_form = this.form_builder.group({
      document: [null, null]
    });
  }

  upload_submit(){
    for (let j = 0; j < this.uploader.queue.length; j++) {
      let item = this.uploader.queue[j];
      let fileItem = item._file;
      this.http.get<any>(
        `${environment.backend}/put/content${this.object_list.get_prefix()}${fileItem.name}`
      ).subscribe(result => {
        let data = new FormData();
        for (const [key, value] of Object.entries(result.message.fields)) {
          if (typeof value === 'string') {
            data.append(key, value);
          }
        };
        data.append('file', fileItem);
        const req = new HttpRequest('POST', result.message.url, data, { reportProgress: true });
        this.http.request(req).subscribe(event => {
          if (event.type === HttpEventType.Sent) {
            item.progress = 0.01;
          } else if (event.type === HttpEventType.UploadProgress) {
            item.progress = Math.round((event.loaded / event.total) * 100);
          } else if (event.type === HttpEventType.Response) {
            setTimeout(() => {
              item.remove();
              if (this.uploader.queue.length === 0) {
                this.object_list.update_list(this.object_list.get_prefix());
              }
            }, 1000);
          }
        });
      });
    }
  }

  set_logged_in(logged_in) {
    if (logged_in) {
      this.jwt = localStorage.getItem('jwt');
    } else {
      this.jwt = null;
    }
  }

  click_file_input() {
    this.file_input.nativeElement.click();
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

}
