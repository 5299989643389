import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})

export class LoginModalComponent {

  private hidden: boolean = true;
  private login_form: FormGroup;
  private loading: boolean = false;

  constructor(private http: HttpClient, private login_service: LoginService, private toastr: ToastrService) {
    this.login_form = new FormGroup({
      username: new FormControl(),
      password: new FormControl()
    })
  }

  close() {
    this.hidden = true;
  }

  show() {
    this.hidden = false;
  }

  login() {
    this.loading = true;
    this.http.post<any>(
      `${environment.backend}/login`, {'username': this.login_form.value.username, 'password': this.login_form.value.password}
    ).subscribe(result => {
      if (result.jwt) {
        this.close();
        this.login_service.login();
      } else {
        this.toastr.error('Invalid credentials', 'Login failed');
      }
      this.loading = false;
    }, err => {
      this.toastr.error('Invalid credentials', 'Login failed');
      this.loading = false;
    });
  }

  keydown(event) {
    if (event.key === "Enter") {
      this.login();
    }
    event.stopPropagation()
  }

}

